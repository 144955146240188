function Footer(props){

    return(
      //Class will be changed, this is temporary
      <footer className="App-header">
        Hello, I'm Jared Long and this is the end of my website
       
      </footer>
    );
}

export default Footer