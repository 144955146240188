import '../styles/CustomNav.css'

function CustomNav(props){

    //ul and list components should all be seperate components, folder for buttons and the like perhaps
    return(
        <nav className = "CustomNav">
            Jared Long
            
            <ul>
                <li>
                    <a href='./#about'> About Me</a>
                </li>
                <li>
                    <a href='./#projects'>Projects</a>
                </li>
                <li>
                    <a href='./#contact'>Contact</a>
                </li>
            </ul>
        </nav>
    );
}

export default CustomNav