function Projects(props){

    return(
      <div id="projects">
        Hello, I'm Jared Long these are projects of mine
        
       
      </div>
    );
}

export default Projects