import './styles/App.css';
import CustomNav from './components/CustomNav';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <CustomNav />
      <Header />
      <About/>
      <Projects/>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
