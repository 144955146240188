function About(props){

    return(
      <div id="about">
        Hello, I'm Jared Long and I think I'm pretty cool
        
       
      </div>
    );
}

export default About