function Header(props){

    return(
      <header className="App-header">
        Hello, I'm Jared Long
       
      </header>
    );
}

export default Header