function Contact(props){

    return(
      <div id="contact">
        Hello, I'm Jared Long please contact me
        
       
      </div>
    );
}

export default Contact